<template>
    <div class="file-upload">
        <div class="wait-upload" v-if="imgUrl == ''" @dragover.prevent="handleDragOver" @drop.prevent="handleDrop"
            @paste="handlePaste" @dragenter="handleDragEnter" @dragleave="handleDragLeave" @click="handleUpload">
            <button class="upload-btn"></button>
            <div class="label">拖拽/粘贴/点击上传新图片</div>
            <button class="history-btn" @click.stop="historyVisible = true">历史图片选择</button>
        </div>
        <div v-if="imgUrl != null & imgUrl != ''" class="img-block">
            <img :src="imgUrl" class="result-img" alt="" @click="handleUpload">

            <div class="oprate">
                <button class="history-icon" @click="historyVisible = true"></button>
                <button class="delete-icon" @click="imgUrl = ''"></button>
            </div>
        </div>

        <!-- 隐藏的文件输入框，用于点击上传 -->
        <input type="file" ref="fileInput" style="display: none" @change="handleFileChange" />
        <!-- 历史图片选择弹窗 -->
        <history-image :visible.sync="historyVisible" @close="historyVisible = false" @select-image="handleSelectImage">
        </history-image>
    </div>
</template>

<script>
import { client } from "@/utils/bosInit";
import HistoryImage from "@/components/video/HistoryImage.vue";

export default {
    components: {
        HistoryImage
    },
    props: {
        value: {  // 父组件传递的值
            type: String,
            default: ''
        }
    },
    watch: {
        // 监听 value 的变化，更新 imgUrl
        value(newVal) {
            this.imgUrl = newVal;
        },
        // 监听 imgUrl 的变化，通知父组件
        imgUrl(newVal) {
            this.$emit('input', newVal);
        }
    },
    data() {
        return {
            historyVisible: false,
            imgUrl: this.value,
            // 添加上传配置常量
            uploadConfig: {
                bucketName: 'goldon-agent',
                baseUrl: 'https://goldon-agent.bj.bcebos.com/',
                pathPrefix: 'ai-video/Goldon',
            },
        };
    },
    methods: {
        // 点击上传按钮时触发文件选择框
        handleUpload() {
            this.$refs.fileInput.click(); // 触发文件输入框的点击事件
        },
        handleSelectImage(imageUrl) {
            this.imgUrl = imageUrl;
            this.$emit('input', imageUrl);
            this.historyVisible = false;
        },
        // 处理文件选择变化（点击或拖拽文件）
        async handleFileChange(e) {
            console.log('触发上传');
            const files = e.target.files;
            if (files.length === 0) return;

            const file = files[0];
            try {
                // 上传文件并获取文件地址
                const imageUrl = await this.uploadBos(file);
                console.log('imageUrl', imageUrl);
                // 将上传的文件地址赋值给表单字段
                this.imgUrl = imageUrl;
                this.$emit('input', imageUrl);
            } catch (error) {
                console.error('上传失败:', error);
            } finally {
                // 清空文件输入框的值
                this.$refs.fileInput.value = '';
            }
        },

        // 处理拖拽事件
        handleDragOver(e) {
            e.preventDefault(); // 阻止默认事件，允许文件被拖拽
        },


        // 处理拖拽进入事件
        handleDragEnter() {
            this.$el.classList.add('dragging'); // 添加 dragging 类
        },

        // 处理拖拽离开事件
        handleDragLeave() {
            this.$el.classList.remove('dragging'); // 移除 dragging 类
        },

        handleDrop(e) {
            const files = e.dataTransfer.files;
            if (files.length > 0) {
                this.handleFileUpload(files[0]);
            }
        },

        // 处理粘贴事件（如从剪贴板粘贴图片）
        handlePaste(e) {
            console.log('粘贴');
            const clipboardItems = e.clipboardData.items;
            for (let i = 0; i < clipboardItems.length; i++) {
                const item = clipboardItems[i];
                if (item.kind === 'file') {
                    const file = item.getAsFile();
                    this.handleFileUpload(file);
                }
            }
        },

        // 处理文件上传逻辑
        async handleFileUpload(file) {
            try {
                const imageUrl = await this.uploadBos(file);
                console.log('imageUrl', imageUrl);
                // 将上传的文件地址赋值给表单字段
                this.imgUrl = imageUrl;
                this.$emit('input', imageUrl);
            } catch (error) {
                console.error('上传失败:', error);
            }
        },

        // 文件上传函数（模拟上传到BOS）
        async uploadBos(file) {
            const suffix = file.name.split('.').pop() || 'png'; // 获取文件扩展名
            const filename = `${this.uploadConfig.pathPrefix}${Date.now()}.${suffix}`; // 构造文件名
            const key = `${filename}`;
            try {
                // 假设使用client.putObjectFromBlob上传文件
                const res = await client.putObjectFromBlob(this.uploadConfig.bucketName, key, file);
                return `${this.uploadConfig.baseUrl}${key}`; // 返回上传后的文件地址
            } catch (error) {
                throw error; // 上传失败时抛出异常
            }
        },
    },
};
</script>

<style scoped lang="scss">
.file-upload {
    width: 100%;
    height: 100%;
    background: #292929;
    border-radius: 4px;
    border: 2px solid #464646;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;


    /* 当拖拽进入时，改变边框样式 */
    &.dragging {
        border: 2px dashed #ff6600;
    }

    &:hover {
        border: 2px dashed #ff6600;
    }
}

.wait-upload {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.upload-btn {
    width: 38px;
    height: 35px;
    background: url('../../assets/images/video/upload-icon.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.label {
    font-family: Source Han Sans CN;
    font-weight: 400;
    font-size: 16px;
    color: #8d8d8d;
    margin-top: 12px;
}

.history-btn {
    margin-top: 31px;
    width: 139px;
    height: 39px;
    background: #464646;
    border-radius: 4px;
    font-weight: 400;
    font-size: 16px;
    color: #8d8d8d;
}

.img-block {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; // 添加相对定位
    overflow: hidden; // 添加溢出隐藏
}

.result-img {
    max-width: 100%; // 限制最大宽度
    max-height: 100%; // 限制最大高度
    height: auto; // 修改为自动高度
    width: auto; // 添加自动宽度
    object-fit: contain;
}

.oprate {
    position: absolute;
    right: 22px;
    bottom: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .history-icon {
        width: 22px;
        height: 22px;
        background: url('../../assets/images/video/history-icon.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-right: 15px;
    }

    .delete-icon {
        width: 22px;
        height: 22px;
        background: url('../../assets/images/video/image-del.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
}
</style>