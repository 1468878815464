<template>
    <el-dialog class="histroy-dialog" :visible="dialogVisible" width="60%" @close="handleClose" :lock-scroll="true"
        :append-to-body="false" :close-on-press-escape="false" :close-on-click-modal="false">

        <i class="el-icon-close" @click="handleClose"></i>


        <div class="image-list">
            <div class="image-item" v-for="(image, index) in imageUrls" :key="index">
                <img :src="image" alt="Image" @click="handleSelectImage(image)" />
            </div>
        </div>
    </el-dialog>
</template>

<script>
import { historyImage } from '@/api/ai/video';
export default {
    props: {
        visible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            dialogVisible: this.visible,
            imageUrls: [
            ]
        };
    },
    watch: {
        visible(newVal) {
            this.dialogVisible = newVal;
            if (newVal) {
                this.fetchHistoryImages();
            }
        }
    },
    created() {
    },
    beforeDestroy() {
    },
    methods: {
        async fetchHistoryImages() {
            try {
                const response = await historyImage();
                this.imageUrls = response.data.map(item => item.uri);
            } catch (error) {
                console.error("获取历史图片失败:", error);
            }
        },
        handleClose() {
            this.$emit('update:visible', false);
        },
        handleCancel() {
            this.$emit('update:visible', false);
        },
        handleConfirm() {
            console.log("点击确定");
            this.$emit('update:visible', false);
        },
        handleSelectImage(image) {
            console.log("点击图片");
            this.$emit('select-image', image);
        }
    }
};
</script>

<style scoped lang="scss">
.histroy-dialog {
    overflow-y: auto;
}

::v-deep .el-dialog__header {
    display: none;
}

::v-deep .el-dialog {
    background: #292929;
    border-radius: 12px;
}

::v-deep .el-dialog__body {
    background: #292929;
    border-radius: 12px;
    border: 1px solid #464646;
    overflow-y: auto;
    height: 70vh;
    padding: 50px;
}

/* 隐藏滚动条 */
::v-deep .el-dialog__body::-webkit-scrollbar {
    /* 隐藏滚动条 */
}

/* 设置滚动条的样式 */
::v-deep .el-dialog__body::-webkit-scrollbar {
    width: 6px;
    /* 设置滚动条宽度 */
}

::v-deep .el-dialog__body::-webkit-scrollbar-thumb {
    background-color: #464646;
    /* 设置滚动条颜色 */
    border-radius: 10px;
    /* 圆角效果 */
}

::v-deep .el-dialog__body::-webkit-scrollbar-thumb:hover {
    background-color: #555;
    /* 鼠标悬停时滚动条颜色 */
}

.image-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-auto-flow: row dense;
    gap: 14px;
}

.image-item {
    width: 100%;
    aspect-ratio: 16/9; // 设置一个基准宽高比，可以根据需要调整
    background: #292929;
    border-radius: 4px;
    border: 2px solid #464646;
    overflow: hidden;
}

.image-item img {
    width: 100%;
    height: 100%;
    object-fit: contain; // 改用 cover 来填充容器
    display: block;
    transition-duration: .3s;

    &:hover {
        transform: scale(1.1);
    }
}

::v-deep .el-icon-close {
    position: absolute;
    right: 29px;
    top: 20px;
    width: 23px;
    height: 23px;
    color: #efefef;
}

::v-deep .el-icon-close:before {
    font-size: 23px;
}
</style>
